import React, { Component } from 'react';

class Ads extends Component {

    render() {

        return(
            <>
                <script data-ad-client="ca-pub-8336043336186608" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
            </>
        )
    }
}

export default Ads