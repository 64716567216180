import React, { Component } from 'react';

class MenuLinks extends Component {

    render() {

        return(
            <>
                
                
                  <a href='/'><li>Home</li></a>
                  <a href='/reactjs'><li>React</li></a>
                  <a href='/about'><li>About</li></a>
                  <a href='/contact'><li>Contact</li></a>
            
                
            </>
        )
    }
}

export default MenuLinks;