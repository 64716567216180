import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {

    render() {

        return(
            <>
            <footer>
            <div>
                Copyright &copy; 2021 PNHCode.com
            </div>
            </footer>
            </>
        )
    }
}

export default Footer;